<template>
  <section class="c_result">
    <h2 class="c_result_ttl">コンテンツ一覧</h2>
    <div class="c_result_filter">
      <label class="c_result_select">
        <select>
          <option value>新着順</option>
        </select>
      </label>
      <div class="c_result_srch">
        <label class="c_result_srch">
          <input
            type="text"
            placeholder="キーワード検索"
            v-model="search"
            @keyup.prevent.enter="doSearch"
          />
        </label>
        <button type="submit">
          <span class="icon-search"></span>
        </button>
      </div>
    </div>
    <div class="c_result_list">
      <div
        class="c_tool_item"
        v-for="c in contents"
        :key="c.id"
        :style="accessableStyle(c)"
      >
        <figure class="c_tool_item_img">
          <img
            v-if="c.image_path"
            :src="`https://${jc3LearningDomain}${c.image_path}`"
            alt
            width="90"
            height="90"
          />
          <img
            v-else
            src="http://placehold.jp/cfcfcf/4a4a4a/90x90.png?text=NO%0AIMAGE"
            alt
            width="90"
            height="90"
          />
        </figure>
        <div class="c_tool_item_cnt">
          <p class="c_tool_item_ttl" :class="{ '-new': isNew(c.from_field) }">
            <em>{{ c.name }}</em>
          </p>
          <p class="c_tool_item_author">
            <em>{{ c.author_name }}/{{ c.author_belonging }}</em>
          </p>
          <p class="c_tool_item_disc">{{ c.description }}</p>
          <div class="c_tool_item_eval">
            <div
              class="c_tool_item_eval mr-1"
              v-if="c.type === 8 || c.type === 16"
            >
              <div class="c_tool_item_eval_star">
                <star-rating
                  :value="c.evaluation_stat.avg"
                  :width="13"
                  :height="13"
                ></star-rating>
              </div>
              <span class="c_tool_item_eval_count">
                ({{ c.evaluation_stat.count }})
              </span>
            </div>
            <div class="c_tool_item_eval c_tool_item_eval_count">
              <div>公開日:</div>
              <time class="mr-1">{{ c.from_field | formatDateHyphen }}</time>
              <div class="c_tool_item_eval" v-if="!c.to_indefinite">
                <div>期限:</div>
                <time class="mr-1">{{ c.to | formatDateHyphen }}</time>
              </div>
            </div>
          </div>
          <p class="c_tool_item_info" v-if="c.type === 16">
            <span>ver {{ c.tool.version }}</span> （<time>{{
              c.tool.created_at | formatDateDot
            }}</time
            >）
          </p>
          <ul class="c_tool_item_tags">
            <li v-for="tag in c.tags" :key="tag.id">
              <a @click.stop="searchTag(tag.text)">{{ tag.text }}</a>
            </li>
          </ul>
        </div>
        <div class="c_tool_item_link">
          <a class="c_tool_item_link" @click="clickContents(c)">{{
            goContentsText(c)
          }}</a>
        </div>
      </div>
      <div :class="{ c_load: loading }"></div>
    </div>
    <div class="c_result_btn">
      <button class="c_btn -large" type="button" v-if="next" @click="getNext">
        さらに表示
      </button>
    </div>
    <div
      v-if="!loading && contents.length === 0"
      style="text-align: center; padding:20px;"
    >
      学習可能なコンテンツはありません。
    </div>
    <!-- アクセス制限 -->
    <modal
      name="unauthorized"
      adaptive
      height="auto"
      width="85%"
      :maxWidth="640"
    >
      <section id="unauthorized" class="c_popup">
        <h2 class="c_popup_ttl">閲覧制限</h2>
        <p class="c_popup_lead">
          このコンテンツを閲覧する権限がありません。
          <br />閲覧制限を解除するには、所属の管理者経由で警察庁にご連絡ください。
        </p>
        <div class="c_popup_btns">
          <button
            class="c_btn"
            type="button"
            @click="$modal.hide('unauthorized')"
          >
            閉じる
          </button>
        </div>
      </section>
    </modal>
  </section>
</template>

<script>
import moment from "moment";
import StarRating from "@/components/StarRating";
import { jc3LearningDomain } from "@/../config.json";
export default {
  components: {
    StarRating,
  },
  props: {
    categoryId: Number,
    videoRouterName: String,
  },
  data() {
    return {
      jc3LearningDomain: jc3LearningDomain,
      contents: [],
      page: 1,
      next: false,
      loading: false,
      search: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;

      this.$authAxios
        .get(`/api/category/${this.categoryId}/contents`, {
          params: {
            page: this.page,
            tool: 1,
            search: this.search,
          },
        })
        .then((response) => {
          this.contents = this.contents.concat(response.data.results);
          this.next = response.data.next;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    doSearch() {
      if (this.search) {
        this.contents = [];
        this.fetch();
      }
    },
    isNew(date) {
      // 3か月以内に作成されてればNEW
      return moment(date).isAfter(moment().subtract(3, "months"));
    },
    getNext() {
      this.page += 1;
      this.fetch();
    },
    clickContents(contents) {
      if (this.$auth.scopes.includes("readonly:npa")) {
        if (
          this.categoryId ===
          this.$store.getters.getCategoryIdByName(
            "サイバー関連サービス等解説コンテンツ"
          )
        ) {
          this.goContents(contents);
        } else {
          this.$modal.show("unauthorized");
        }
      } else {
        this.goContents(contents);
      }
    },
    goContents(contents) {
      if (contents.type === 1 || contents.type === 2) {
        // CTF or 教育
        let url = `https://${this.jc3LearningDomain}/events/${contents.id}`;
        window.open(url, "_blank");
      } else if (contents.type === 4) {
        // 外部サイト
        let url = contents.external_url;
        window.open(url, "_blank");
      } else if (contents.type === 8) {
        // 動画
        this.$router.push({
          name: this.videoRouterName,
          params: { id: contents.id },
        });
      } else if (contents.type === 16) {
        // ツール
        this.$router.push({ name: "ToolSingle", params: { id: contents.id } });
      }
    },
    goContentsText(contents) {
      if (contents.type === 1 || contents.type === 2 || contents.type === 4) {
        // CTF or 教育 or 外部サイト
        return "演習に行く";
      } else if (contents.type === 8) {
        // 動画
        return "動画を見る";
      } else if (contents.type === 16) {
        // ツール
        return "詳細を見る";
      }
    },
    accessableStyle(contents) {
      if (!contents.accessable) {
        return {
          "pointer-events": "none",
          background: "gainsboro",
        };
      }
    },
    searchTag(tag) {
      this.$router.push({ name: "Search", query: { tag: tag } });
    },
  },
};
</script>
